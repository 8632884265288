<template>
  <div class="cart-thumbnail">
    <el-image class="goods-image-in-cart" :src="goods.picture" fit="cover"></el-image>
    <div class="middle">
      <div class="goods-title">{{goods.name}}{{index}}</div>
      <div class="goods-serial-number">{{goods.serial_number}}</div>
      <div class="goods-selling-price">￥{{goods.selling_price}}</div>
    </div>
    <i class="el-icon-close delete-icon" @click="deleteCartGoods"></i>
  </div>
</template>

<script>
export default {
  props: ['goods', 'index'],
  methods: {
    deleteCartGoods () {
      const index = this.index
      this.$confirm('从购物车中删除该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.commit('deleteGoodsFormShoppingCart', index)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>

<style scopd>
.cart-thumbnail{
  display: flex;
  align-items: center;
  height: 60px;
}
.middle{
  flex: 1;
  box-sizing: border-box;
  padding-left: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.goods-image-in-cart{
  width: 60px;
  height: 60px;
}
.goods-title{
  font-size: 14px;
  color: #303133;
}
.goods-serial-number{
  font-size: 12px;
  color: #606266;
}
.goods-selling-price{
  font-size: 14px;
  font-weight: bold;
  color: #F56C6C;
}
.delete-icon{
  cursor: pointer;
  font-size: 15px;
}
.delete-icon:hover{
  color: red;
}
</style>
