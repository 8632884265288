import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    shoppingCart: []
  },
  mutations: {
    addGoodsToShoppingCart (state, goods) {
      let inCart = false
      for (let i = 0; i < state.shoppingCart.length; i++) {
        const item = state.shoppingCart[i]
        if (item.ind === goods.ind) {
          state.shoppingCart[i].num++
          inCart = true
        }
      }
      if (inCart === false) {
        state.shoppingCart.push(goods)
      }
    },
    deleteGoodsFormShoppingCart (state, index) {
      state.shoppingCart.splice(index, 1)
    }
  },
  actions: {
  },
  modules: {
  }
})
