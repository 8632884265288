<template>
  <div class="base-view">
    <NavDefault></NavDefault>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import NavDefault from '@/components/nav/NavDefault.vue'
export default {
  components: {
    NavDefault
  }
}
</script>

<style scoped>
.base-view{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.content {
  flex: 1;
  overflow: hidden;
}
</style>
