<template>
  <el-menu :default-active="activeIndex" class="nav-bar" router mode="horizontal">
  <el-menu-item index="/">首页</el-menu-item>
  <el-submenu index="2">
    <template slot="title">商品管理</template>
    <el-menu-item index="/goods_list">商品列表</el-menu-item>
    <el-menu-item index="/new_goods">添加商品</el-menu-item>
    <el-menu-item index="/goods_category">商品分类管理</el-menu-item>
    <el-menu-item index="/goods_tool">商品管理工具</el-menu-item>
  </el-submenu>
  <el-submenu index="3">
    <template slot="title">微信小程序设置</template>
    <el-menu-item index="/wxxcx_filters">筛选设置</el-menu-item>
  </el-submenu>
  <el-submenu index="4">
    <template slot="title">订单管理</template>
    <el-menu-item index="/order/list">订单列表</el-menu-item>
    <el-menu-item index="/order/tofactory">下单中心</el-menu-item>
    <el-menu-item index="/order/create">创建订单</el-menu-item>
  </el-submenu>
  <el-menu-item index="/todo">待办事项</el-menu-item>
  <div class="flex-grow"></div>
  <el-menu-item>
    <div class="cart-wrap">
      <el-popover placement="top-start" width="300" trigger="hover">
        <div v-if="shoppingCartGoods.length > 0">
          <CartThumbnail :goods="goods" v-for="(goods, index) in shoppingCartGoods" :key="index" style="margin-bottom:10px" :index="index" />
          <div class="check-out" @click="goto('/order/create')">去结算</div>
        </div>
        <el-empty v-else :image-size="100" description="购物车还没有任何商品"></el-empty>
        <span slot="reference" class="cart"><i class="el-icon-shopping-cart-1 cart-icon"></i>购物车 <span class="cart-goods-num" v-if="shoppingCartGoods.length > 0">{{shoppingCartGoods.length>99?'99+':shoppingCartGoods.length}}</span></span>
      </el-popover>
    </div>
  </el-menu-item>
  <el-menu-item>
    <el-dropdown>
      <div class="staff-info">
        <div class="staff-name">{{staff.name}} </div>
        <el-avatar :size="32" :alt="staff.name" shape="circle">{{staff.name}}</el-avatar>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item icon="el-icon-lock"  divided @click.native="changePassword"><UpdatePasswords /></el-dropdown-item>
        <el-dropdown-item icon="el-icon-switch-button"  divided @click.native="handleExit">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </el-menu-item>
</el-menu>
</template>

<script>
import CartThumbnail from '@/components/goods/CartThumbnail.vue'
import UpdatePasswords from '@/components/user/UpdatePasswords.vue'
export default {
  components: {
    CartThumbnail,
    UpdatePasswords
  },
  data () {
    return {
      activeIndex: '1',
      staff: {}
    }
  },
  computed: {
    shoppingCartGoods () {
      return this.$store.state.shoppingCart
    }
  },
  methods: {
    changePassword () {

    },
    getStaffInfo () {
      const staff = localStorage.getItem('staff')
      this.staff = JSON.parse(staff)
    },
    handleExit () {
      this.$router.replace({ name: 'login' })
    },
    goto (path) {
      this.$router.push(path)
    }
  },
  mounted () {
    this.getStaffInfo()
  }
}
</script>

<style scoped>
.nav-bar{
  display: flex;
}
.flex-grow {
  flex-grow: 1;
}
.staff-info{
  display: flex;
  align-items: center;
}
.staff-name{
  padding: 1px 15px;
  font-size: 14px;
  color: #303133;
  cursor: pointer;
}
.cart{
  color: #303133;
  display: flex;
  align-items: center;
}
.cart-icon{
  font-size: 12px;
}
.cart-goods-num{
  background-color: #F56C6C;
  color: white;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin-left: 5px;
  line-height: 20px;
  text-align: center;
}
.check-out{
  border: 1px solid #909399;
  color: #909399;
  text-align: center;
  font-size: 14px;
  padding: 6px 0;
  cursor: pointer;
  border-radius: 2px;
}
.check-out:hover{
  border-color: #303133;
  color: #303133;
  font-weight: bold;
}
</style>
