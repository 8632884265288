<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style>
:root {
  --main-bg-color: #f5f5f5;
}
html,body,#app{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.g-drawer-wrap{
  box-sizing: border-box;
  padding: 0 15px 15px;
}
.g-red{
  color: red;
}
.g-f-c{
  display: flex;
  align-items: center;
}
.g-pagination-wrap{
  background-color: white;
  padding: 10px 5px;
  text-align: center;
}
</style>
