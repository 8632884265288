import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseView from '../views/BaseView.vue'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: BaseView,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView
      },
      {
        path: 'goods_list',
        meta: { title: '商品列表' },
        component: () => import('../views/goods/GoodsList.vue')
      },
      {
        path: 'new_goods',
        meta: { title: '添加商品' },
        component: () => import('../views/goods/NewGoods.vue')
      },
      {
        path: 'goods_category',
        meta: { title: '商品分类管理' },
        component: () => import('../views/goodsCategory/CategoryList.vue')
      },
      {
        path: 'goods_tool',
        meta: { title: '商品管理工具' },
        component: () => import('../views/goodsTool/ZoomSellingPrice.vue')
      },
      {
        path: 'todo',
        name: 'todo',
        meta: { title: '待办事项' },
        component: () => import('../views/ToDo.vue')
      },
      {
        path: 'wxxcx_filters',
        meta: { title: '微信筛选设置' },
        component: () => import('../views/wxxcx/FiltersView.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
      }
    ]
  },
  {
    path: '/order',
    name: 'order',
    component: BaseView,
    children: [
      {
        path: 'create',
        name: 'orderCreate',
        meta: { title: '创建订单' },
        component: () => import('../views/order/CreateView.vue')
      },
      {
        path: 'list',
        name: 'orderList',
        meta: { title: '订单列表' },
        component: () => import('../views/order/ListView.vue')
      },
      {
        path: 'tofactory',
        name: 'ToFactory',
        meta: { title: '下单中心' },
        component: () => import('../views/order/ToFactory.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = 'SL-OMS'
  }
  if (token == null && to.name !== 'login') {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
