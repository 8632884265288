<template>
  <div class="update-passwords">
    <span @click="handleClick">修改密码</span>
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="400px" append-to-body>
      <el-form :model="form" ref="form" :rules="rules" :inline="false" label-width="80px" size="small">
        <el-form-item label="当前密码" prop="currentPasswords">
          <el-input v-model="form.currentPasswords" placeholder="请输入当前密码" clearable show-password prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPasswords">
          <el-input v-model="form.newPasswords" placeholder="请输入新密码" clearable show-password prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="comfirmPasswords">
          <el-input v-model="form.comfirmPasswords" placeholder="再次输入新密码" clearable show-password prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onUpdatePassword('form')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.newPasswords) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      rules: {
        currentPasswords: [
          { required: true, message: '当前密码不能为空', trigger: 'blur' }
        ],
        newPasswords: [
          { required: true, message: '新密码不能为空', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
        ],
        comfirmPasswords: [
          { validator: validatePass, trigger: 'blur' }
        ]
      },
      form: {
        currentPasswords: '',
        newPasswords: '',
        comfirmPasswords: ''
      }
    }
  },
  methods: {
    handleClick () {
      this.resetForm('form')
      this.dialogVisible = true
    },
    resetForm (formName) {
      if (this.$refs[formName] !== undefined) {
        this.$refs[formName].resetFields()
      }
    },
    onUpdatePassword (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios.put('my/password', this.form).then(function (resp) {
            if (resp.data.code === 0) {
              that.$message.success('密码已更新')
              that.$refs[formName].resetFields()
              that.dialogVisible = false
            }
            if (resp.data.code === 1) {
              that.$message.error(resp.data.msg)
            }
          }).catch(function () {
            that.$message.error('发生未知错误')
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
.update-passwords{
  display: inline;
}
</style>
